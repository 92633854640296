<template>
  <div :class="{ 'apartment-card': true, 'apartment-card--full': full }">
    <div class="apartment-card__body">
      <div class="apartment-card__floor">
        <img class="apartment-card__floor-image" :src="planImage" />
      </div>
      <div class="apartment-card__container apart-container">
        <div class="apart-container__top">
          <div class="apart-container__name">
            {{ apartmentName }}
          </div>
          <div class="apart-container__location">
            Секция {{ korpus }}, этаж {{ floor }}
          </div>
          <div v-if="status === 'booked'" class="apart-container__booked">
            <img
              class="apart-container__booked-img"
              src="@/assets/images/icons/booked.png"
            />
            <div class="apart-container__booked-text">Бронь</div>
          </div>
          <div class="apart-container__number">№{{ number }}</div>
        </div>
        <div class="apart-container__bottom">
          <div class="apart-container__cost">
            <div class="apart-container__cost-now_min">
              <div v-if="newCost" class="apart-container__cost-old">
                <span>{{ parseInt(cost).toLocaleString("ru") }} ₽</span>
              </div>
              <div class="apart-container__cost-current">
              {{ parseInt(newCost ? newCost : cost).toLocaleString("ru") }} ₽<span
              v-if="properties.with_decoration_design || properties.with_decoration_whitebox">*</span>
              </div>
            </div>
            <div
              v-if="status !== 'available_agent'"
              class="apart-container__cost-now"
            >
              или от {{ parseInt(price).toLocaleString("ru") }} ₽/мес
            </div>
            <div v-if="properties.with_decoration_design || properties.with_decoration_whitebox" class="apart-container__cost-info">
              *отделка включена
            </div>
          </div>
          <div
            :class="[
              'apart-container__type',
              { 'apart-container__type--no-text': hideTagText },
            ]"
          >
            <template
              v-if="
                properties.with_decoration_furnished ||
                properties.with_decoration_finishing ||
                properties.with_decoration_design ||
                properties.with_decoration_whitebox
              "
            >
              <div v-if="properties.with_decoration_furnished" class="apart-container__type-item">
                <div class="apart-container__type-tag">
                  <img
                    class="apart-container__type-icon"
                    src="@/assets/images/icons/decoration_orange.svg"
                    alt=""
                    loading="lazy"
                  />
                  <span class="apart-container__type-text orange">
                    С отделкой и мебелью
                  </span>
                </div>
              </div>
              <div v-if="properties.with_decoration_finishing" class="apart-container__type-item">
                <div class="apart-container__type-tag">
                  <img
                    class="apart-container__type-icon"
                    src="@/assets/images/icons/decoration_dark.svg"
                    alt=""
                    loading="lazy"
                  />
                  <span class="apart-container__type-text">
                    Чистовая отделка
                  </span>
                </div>
              </div>
              <div v-if="properties.with_decoration_design" class="apart-container__type-item">
                <div class="apart-container__type-tag">
                  <img
                    class="apart-container__type-icon"
                    src="@/assets/images/icons/decoration_orange.svg"
                    alt=""
                    loading="lazy"
                  />
                  <span class="apart-container__type-text orange">
                    Дизайнерская отделка
                  </span>
                </div>
              </div>
              <div v-if="properties.with_decoration_whitebox" class="apart-container__type-item">
                <div class="apart-container__type-tag">
                  <img
                    class="apart-container__type-icon"
                    src="@/assets/images/icons/decoration_orange.svg"
                    alt=""
                    loading="lazy"
                  />
                  <span class="apart-container__type-text orange">
                    Отделка White Box
                  </span>
                </div>
              </div>
            </template>
            <div v-else-if="properties.with_decoration" class="apart-container__type-item">
              <div class="apart-container__type-tag">
                <img
                  class="apart-container__type-icon"
                  src="@/assets/images/icons/decoration_dark.svg"
                  alt=""
                  loading="lazy"
                />
                <span class="apart-container__type-text">
                  Возможна отделка<br>от 27 000 р.
                </span>
              </div>
            </div>
            <div v-if="alternative" class="apart-container__type-item">
              <div class="apart-container__type-tag">
                <img
                  class="apart-container__type-icon"
                  src="@/assets/images/icons/plans.svg"
                  alt=""
                  loading="lazy"
                />
                <div class="apart-container__type-text">
                  Возможные планировки
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApartmentCard",
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    alternative: {
      type: Boolean,
      default: false,
    },
    hideTagText: {
      type: Boolean,
      default: false,
    },
    properties: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: String,
    },
    price: {
      type: [String, Number],
      required: true,
    },
    planImage: {
      type: String,
      required: true,
    },
    area: {
      required: true,
    },
    rooms: {
      required: true,
    },
    floor: {
      required: true,
    },
    korpus: {
      required: true,
    },
    number: {
      required: true,
    },
    cost: {
      required: true,
    },
    newCost: {
      required: false,
      default: 0,
    },
    type: {
      default: "",
    },
  },
  computed: {
    apartmentName() {
      return this.formatQuantity(this.rooms) + ", " + this.area + " м²";
    },
  },
};
</script>

<style scoped lang="scss">
.apartment-card {
  $this: &;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 6px 8px 16px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 300ms ease-out;
  overflow: hidden;
  min-height: 155px;

  @media (max-width: ($screen-lg - 1)) {
    min-height: 137px;
  }

  &:hover {
    box-shadow: 6px 8px 16px rgba(0, 0, 0, 0.1), 6px 8px 16px rgba(0, 0, 0, 0.1);
  }

  &__body {
    flex: 1;
    padding: 20px 0 20px 10px;
    display: flex;
    position: relative;
    @media screen and (min-width: $screen-sm) {
      padding: 20px 20px 20px 10px;
    }
    @media screen and (min-width: $screen-xs) {
      padding-left: 20px;
    }
  }

  &__floor {
    &-image {
      width: 62px;
      max-height: 85px;
      object-fit: contain;

      @media screen and (min-width: $screen-sm) {
        max-height: 108px;
        width: 82px;
      }
    }

    margin-right: 13px;
  }
  &__container,
  .apart-container {
    padding-top: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__name {
      font-family: $font-family-bebas;
      font-weight: 700;
      font-size: 16px;
      line-height: 132.5%;
      color: #000000;

      @media screen and (min-width: $screen-sm) {
        font-size: 20px;
      }
    }

    &__location {
      font-family: $font-family-rubik;
      font-weight: 300;
      font-size: 10px;
      line-height: 132.5%;
      color: #000000;
      opacity: 0.6;
      margin-bottom: 12px;

      @media screen and (min-width: $screen-sm) {
        font-size: 10px;
      }

      @media screen and (min-width: 1200px) {
        margin-bottom: 21px;
      }
    }

    &__number {
      font-family: $font-family-rubik;
      font-weight: 300;
      font-size: 10px;
      line-height: 132.5%;
      text-align: right;
      color: #18181b;
      opacity: 0.3;
      position: absolute;
      top: 10px;
      right: 20px;

      @media screen and (min-width: $screen-sm) {
        font-size: 12px;
        top: 20px;
      }
    }

    &__bottom {
      display: flex;
    }

    &__type {
      position: relative;
      height: 20px;
      padding-left: 15px;
      font-family: $font-family-rubik;
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 132.5%;
      color: #000000;
      display: flex;

      &--no-text {
        .apart-container__type {
          &-text {
            display: none;
          }
        }
      }

      $tag-height: 18px;

      &:not(:empty) {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 2px;
          height: $tag-height;
          background-color: #ccc;
        }
      }

      @media screen and (min-width: $screen-sm) {
        font-size: 12px;
        flex-direction: row;
      }

      &-item {
        display: flex;
        height: $tag-height;
        margin-right: 4px;

        @media screen and (min-width: $screen-mini) {
          display: block;
        }

        @media screen and (min-width: $screen-sm) {
          margin-right: 15px;
        }

        &:empty {
          display: none;
        }
      }

      &-tag {
        display: flex;
        align-items: center;
        height: 100%;
      }

      &-text {
        position: relative;
        top: 1.5px;
        display: none;
        &.orange {
          color:#E14313
        }

        @media screen and (min-width: $screen-mini) {
          display: block;
        }

        @media screen and (min-width: 868px) {
          display: none;
        }

        @media screen and (min-width: 768px) {
          display: inline;
        }
      }

      &-icon {
        width: 18px;
        max-height: 100%;
        object-fit: contain;
        padding-right: 5px;
      }
    }

    &__cost {
      position: relative;
      padding-right: 15px;

      &-now_min {
        font-family: $font-family-rubik;
        font-weight: 500;
        font-size: 12px;
        line-height: 132.5%;
        color: #000000;
        letter-spacing: 0.5px;
        text-align: left;

        @media screen and (min-width: $screen-sm) {
          font-size: 17px !important;
        }
      }

      &-now {
        font-family: $font-family-rubik;
        font-weight: 500;
        font-size: 12px;
        line-height: 132.5%;
        color: #000000;
        opacity: 0.6;
        letter-spacing: 0.5px;

        @media screen and (min-width: $screen-sm) {
          font-size: 15px !important;
        }
      }

      &-old {
        position: relative;
        display: inline-block;
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background: #EC6337;
          position: absolute;
          top: 49%;
          left: 0;
          display: block;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 132.5%;
          color: #000000;
          opacity: 0.3;
          display: block;
          @media screen and (min-width: 768px) {
            font-size: 15px;
          }
        }
      }
    }
    &__cost-info {
      color: #ff0000;

      @media (max-width: ($screen-lg - 1)) {
        display: none;
      }
    }
  }

  &--full {
    min-height: 137px;
    .apart-container {
      @media screen and (min-width: $screen-lg) {
        flex-direction: row;
        justify-content: space-between;
      }

      &__name {
        display: flex;
        @media screen and (min-width: $screen-lg) {
          font-size: 26px;
        }
        &-action {
          color: #ff5722;
        }
      }

      &__location {
        @media screen and (min-width: $screen-lg) {
          font-size: 14px;
        }
      }

      &__number {
        @media screen and (min-width: $screen-lg) {
          font-size: 13px;
        }
      }

      &__booked {
        display: flex;
        &-img {
          display: none;
          @media screen and (min-width: $screen-sm) {
            display: inline;
            position: absolute;
            top: 18px;
            right: 146px;
          }
        }
        &-mob {
          display: inline;
          padding-right: 10px;
          @media screen and (min-width: $screen-sm) {
            display: none;
          }
        }
        &-text {
          display: none;
          @media screen and (min-width: $screen-sm) {
            display: inline;
            opacity: 0.3;
            font-family: Rubik, serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 132.5%;
            text-align: right;
            color: #18181b;
            position: absolute;
            top: 20px;
            right: 100px;
          }
        }
      }

      &__type {
        &:not(:empty) {
          &:before {
            @media (min-width: $screen-lg) {
              display: none;
            }
          }
        }

        @media screen and (min-width: $screen-lg) {
          position: absolute;
          bottom: 14px;
          left: 100px;
          font-size: 14px;
        }
      }

      &__cost-current span,
      &__cost-info {
        color: #ff0000;

        @media (max-width: ($screen-lg - 1)) {
          display: none;
        }
      }

      &__cost {
        @media screen and (min-width: $screen-lg) {
          border-left: 1px solid #e6e6e6;
          padding-right: 0;
          padding-left: 30px;
          padding-top: 20px;
          text-align: right;
        }

        &-now {
          @media screen and (min-width: $screen-lg) {
            font-size: 22px;
          }
        }

        &-old {
          @media screen and (min-width: $screen-lg) {
            font-size: 14px;
          }
        }
      }

      &__cost-info {
        @media (min-width: $screen-lg) {
          position: absolute;
          bottom: -2px;
          font-family: $font-family-rubik;
          font-size: 12px;
          line-height: 1;
        }
      }
    }
  }
}
</style>
