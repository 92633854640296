var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "apartment-card": true, "apartment-card--full": _vm.full } },
    [
      _c("div", { staticClass: "apartment-card__body" }, [
        _c("div", { staticClass: "apartment-card__floor" }, [
          _c("img", {
            staticClass: "apartment-card__floor-image",
            attrs: { src: _vm.planImage },
          }),
        ]),
        _c(
          "div",
          { staticClass: "apartment-card__container apart-container" },
          [
            _c("div", { staticClass: "apart-container__top" }, [
              _c("div", { staticClass: "apart-container__name" }, [
                _vm._v(" " + _vm._s(_vm.apartmentName) + " "),
              ]),
              _c("div", { staticClass: "apart-container__location" }, [
                _vm._v(
                  " Секция " +
                    _vm._s(_vm.korpus) +
                    ", этаж " +
                    _vm._s(_vm.floor) +
                    " "
                ),
              ]),
              _vm.status === "booked"
                ? _c("div", { staticClass: "apart-container__booked" }, [
                    _c("img", {
                      staticClass: "apart-container__booked-img",
                      attrs: {
                        src: require("@/assets/images/icons/booked.png"),
                      },
                    }),
                    _c("div", { staticClass: "apart-container__booked-text" }, [
                      _vm._v("Бронь"),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "apart-container__number" }, [
                _vm._v("№" + _vm._s(_vm.number)),
              ]),
            ]),
            _c("div", { staticClass: "apart-container__bottom" }, [
              _c("div", { staticClass: "apart-container__cost" }, [
                _c("div", { staticClass: "apart-container__cost-now_min" }, [
                  _vm.newCost
                    ? _c("div", { staticClass: "apart-container__cost-old" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(parseInt(_vm.cost).toLocaleString("ru")) +
                              " ₽"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "apart-container__cost-current" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          parseInt(
                            _vm.newCost ? _vm.newCost : _vm.cost
                          ).toLocaleString("ru")
                        ) +
                        " ₽"
                    ),
                    _vm.properties.with_decoration_design ||
                    _vm.properties.with_decoration_whitebox
                      ? _c("span", [_vm._v("*")])
                      : _vm._e(),
                  ]),
                ]),
                _vm.status !== "available_agent"
                  ? _c("div", { staticClass: "apart-container__cost-now" }, [
                      _vm._v(
                        " или от " +
                          _vm._s(parseInt(_vm.price).toLocaleString("ru")) +
                          " ₽/мес "
                      ),
                    ])
                  : _vm._e(),
                _vm.properties.with_decoration_design ||
                _vm.properties.with_decoration_whitebox
                  ? _c("div", { staticClass: "apart-container__cost-info" }, [
                      _vm._v(" *отделка включена "),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  class: [
                    "apart-container__type",
                    { "apart-container__type--no-text": _vm.hideTagText },
                  ],
                },
                [
                  _vm.properties.with_decoration_furnished ||
                  _vm.properties.with_decoration_finishing ||
                  _vm.properties.with_decoration_design ||
                  _vm.properties.with_decoration_whitebox
                    ? [
                        _vm.properties.with_decoration_furnished
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [_vm._m(0)]
                            )
                          : _vm._e(),
                        _vm.properties.with_decoration_finishing
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [_vm._m(1)]
                            )
                          : _vm._e(),
                        _vm.properties.with_decoration_design
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [_vm._m(2)]
                            )
                          : _vm._e(),
                        _vm.properties.with_decoration_whitebox
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [_vm._m(3)]
                            )
                          : _vm._e(),
                      ]
                    : _vm.properties.with_decoration
                    ? _c("div", { staticClass: "apart-container__type-item" }, [
                        _vm._m(4),
                      ])
                    : _vm._e(),
                  _vm.alternative
                    ? _c("div", { staticClass: "apart-container__type-item" }, [
                        _vm._m(5),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_orange.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text orange" }, [
        _vm._v(" С отделкой и мебелью "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_dark.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text" }, [
        _vm._v(" Чистовая отделка "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_orange.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text orange" }, [
        _vm._v(" Дизайнерская отделка "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_orange.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text orange" }, [
        _vm._v(" Отделка White Box "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_dark.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text" }, [
        _vm._v(" Возможна отделка"),
        _c("br"),
        _vm._v("от 27 000 р. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/plans.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("div", { staticClass: "apart-container__type-text" }, [
        _vm._v(" Возможные планировки "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }